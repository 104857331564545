import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import getDataFromApi from "../../api/getDataFromApi";

export default function WorkerLinkSearch({ currentSearchStatus, updateValues }) {
  const [priority, setPriority] = useState(currentSearchStatus.priority);
  const [district, setDistrict] = useState(currentSearchStatus.district);
  const [freeSearch, setFreeSearch] = useState(currentSearchStatus.freeSearch);
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setFreeSearch(event.target.value);
    updateValues({
      freeSearch: event.target.value,
    });
  };

  const handleSelect = (event) => {
    const { name, value } = event.target;
    if (name === "priority") {
      setPriority(value);
    } else if (name === "district") {
      setDistrict(value);
    }

    updateValues({
      priority: name === "priority" ? value : priority,
      district: name === "district" ? value : district,
    });
  };

  useEffect(() => {
    const sendtoAdmin = async () => {
      setLoading(true);
      try {
        const data = await getDataFromApi("workerLinkFields", {}, "app", "all");
        setResponse(data);
        console.log(data, "data");
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    sendtoAdmin();
  }, []);

  return (
    <div className="WorkerLinkSearch">
      {response && (
        <div className="WorkerLinkSearchCont animate__animated animate__fadeIn">
          <h3>סינון</h3>

          <div className="inputs">
            <InputGroup className="inputGroup">
              <FormControl
                as="select"
                className="customSelectInput noLabel"
                onChange={handleSelect}
                value={priority}
                name="priority"
              >
                <option value="">תעדוף...</option>
                {response.workerPriorities.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.value}
                  </option>
                ))}
              </FormControl>
            </InputGroup>

            <InputGroup className="inputGroup">
              <FormControl
                as="select"
                className="customSelectInput noLabel"
                onChange={handleSelect}
                value={district}
                name="district"
              >
                <option value="">מחוז...</option>
                {response.districts.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.value}
                  </option>
                ))}
              </FormControl>
            </InputGroup>

            <InputGroup className="inputGroup">
              <FormControl
                className="customInput searchIco"
                placeholder="חיפוש חופשי"
                onChange={handleChange}
                value={freeSearch}
                name="freeSearch"
              />
            </InputGroup>
          </div>
        </div>
      )}
    </div>
  );
}
