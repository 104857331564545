import React, { Component } from "react";
import edit from "./../../../img/nathan/svg/edit.svg";

import { orderSatus } from "./../../models/ordersFuncNathan";

import WorkerConfirm from "./WorkerConfirm";

import { replaceText } from "../../models/ordersFuncNathan";

export default class OrderTD extends Component {
  render() {
    let item = this.props.item;

    //console.log(item);1

    let btnStatus = orderSatus(item.orderStatus);

    return (
      <div
        onClick={() => this.props.sendOrderId(item.id)}
        className={`tableTR ${item.orderStatus === "0" || item.cancelRequest ? "alert" : ""}`}
      >
        <div className="tableTD"> {item.id} </div>
        <div className="tableTD"> {item.targetDayShow} </div>
        <div className="tableTD"> {item.hospitalShift} </div>

        <div className="tableTD">
          <div className={`showStatus ${btnStatus.nameClass}`}>{replaceText(btnStatus.text)}</div>
          <WorkerConfirm orderData={item} />

        </div>
        <div className="tableTD"> {item.hospital} </div>
        <div className="tableTD"> {item.hospitalDivision} </div>

        <div className="tableTD"> {item?.nurseData?.name} </div>
        {/* <div className="tableTD"> {item.patientName} </div> */}

        <div className="tableTD">
          <span className="middle">{item.contactName}</span>
          <button className="editBtn">
            <img src={edit} alt="ערוך" />
          </button>
        </div>
      </div>
    );
  }
}
