import React, { useState, useEffect } from "react";
import WorkerLinkSearch from "./WorkerLinkSearch";
import getDataFromApi from "../../api/getDataFromApi";
import loader from "./../../../img/preLoader.gif";
import WorkersList from "./WorkersList";
import changeIcon from "./../../../img/nathan/svg/change.svg";
import WorkerEdit from "./WorkerEdit";
import { RestUrls } from "./../../api/config";
import WorkerConfirm from "./WorkerConfirm";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import md5 from "md5";

const WorkerLinkMain = ({
  orderData,
  limit = 3,
  editNurse,
  is_4selectWorker,
  reloadOrders,
}) => {
  const [state, setState] = useState(() => {
    // Try to get saved filters from localStorage
    const savedFilters = localStorage.getItem('orderFilters');
    const defaultState = {
      page: 0,
      startPage: 0,
      limit: 6,
      response: false,
      loading: false,
      selectedNurse: orderData?.nurseData?.name ? orderData.nurseData : false,
      orderField: "priorityId",
      orderAscDesc: "DESC",
      newOrderNurseId: false,
      priority: "",
      district: "",
      freeSearch: "",
      workerBonus: false,
      editNurseData: false,
    };

    // If we have saved filters, use them
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      return {
        ...defaultState,
        priority: filters.priority || "",
        district: filters.district || "",
        freeSearch: filters.freeSearch || "",
        orderField: filters.orderField || "priorityId",
        orderAscDesc: filters.orderAscDesc || "DESC"
      };
    }

    return defaultState;
  });

  // Save filters when they change
  useEffect(() => {
    const filtersToSave = {
      priority: state.priority,
      district: state.district,
      freeSearch: state.freeSearch,
      orderField: state.orderField,
      orderAscDesc: state.orderAscDesc
    };
    localStorage.setItem('orderFilters', JSON.stringify(filtersToSave));
  }, [state.priority, state.district, state.freeSearch, state.orderField, state.orderAscDesc]);

  const user = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (!state.loading) {
      getNurses();
    }
  }, [state.priority, state.district, state.freeSearch, state.page, state.startPage]);

  const getNurses = () => {
    console.log("Getting nurses with state:", state);
    
    const sendObj = {
      orderField: state.orderField,
      orderAscDesc: state.orderAscDesc,
      priority: state.priority,
      district: state.district,
      freeSearch: state.freeSearch,
      page: state.page,
      startPage: state.startPage,
      limit: state.limit,
      orderId: orderData ? orderData.id : "",
    };

    console.log("Sending request with:", sendObj);
    setState(prev => ({...prev, loading: true}));
    
    sendtoAdmin("getNurses", "nurses", sendObj, "response");
  };

  const updateSearchValues = (values) => {
    console.log("Updating search values:", values);
    setState((prev) => ({ 
      ...prev,
      priority: values.priority || "",
      district: values.district || "",
      freeSearch: values.freeSearch || "",
      page: 0
    }));
  };

  const changePage = (setPage) => {
    console.log("Changing page to:", setPage);
    setState((prev) => ({ ...prev, page: setPage }));
    getNurses();
  };

  const changeStartPage = (action) => {
    const changePage =
      action === "+"
        ? Math.min(state.startPage + 10, state.response.totalPages - 1)
        : Math.max(state.startPage - 10, 0);

    console.log("Changing start page:", action, changePage);
    setState((prev) => ({ ...prev, startPage: changePage }));
    getNurses();
  };

  const selectNurse = (id, smsMessage = false) => {
    const sendObj = {
      orderId: orderData.id,
      nurseId: id,
      smsMessage,
      workerBonus: state.workerBonus,
      hospitalId: orderData?.hospitalId,
      hospitalDivisionId: orderData?.hospitalDivisionId,
      targetDay: orderData?.targetDay,
    };

    console.log("Selecting nurse:", sendObj);
    setState((prev) => ({ ...prev, newOrderNurseId: id }));
    sendtoAdmin("assignNurseToOrder", "nurses", sendObj, "responseAssignNurse");
  };

  const changeOrder = (order) => {
    console.log("Changing order:", order);
    setState((prev) => ({
      ...prev,
      orderField: order.name,
      orderAscDesc: order.order,
    }));
    getNurses();
  };

  const changeSelectedNurse = (nurseData) => {
    console.log("Changing selected nurse:", nurseData);
    
    if (!nurseData) {
      // Don't reset filters when going back, just reset selection
      setState((prev) => ({ 
        ...prev, 
        selectedNurse: false,
        page: 0,
        startPage: 0
      }));
    } else {
      setState((prev) => ({ 
        ...prev, 
        selectedNurse: nurseData
      }));
    }
  };

  const goBack = (states) => {
    console.log("Going back with states:", states);
    setState((prev) => ({ ...prev, ...states }));
    if (states.reloadOrders) {
      getNurses();
    }
  };

  const confirmOrderLink = () => {
    const nurseId = state.newOrderNurseId || orderData.nurseData.id;
    const token = md5(orderData.hospitalshiftId + "|" + nurseId);
    const orderId = orderData.id;
    const endUrl = `?token=${token}&orderId=${orderId}`;
    const url = `${RestUrls.appUrl}/confirm/${endUrl}`;

    console.log("Confirming order link:", url);
    window.open(url, "_blank");
  };

  const sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    console.log("Sending to admin:", url, objectToSend);
    setState((prev) => ({ ...prev, loading: true }));

    getDataFromApi(url, objectToSend, controller, auth).then((getData) => {
      console.log("Received data from API:", getData);
      
      if (is_4selectWorker && getData.nurses) {
        getData.nurses = getData.nurses.filter(nurse => nurse.priorityId !== "4");
      }
      
      setState((prev) => ({
        ...prev,
        [setStateName]: getData,
        loading: false,
      }));

      if (url === "assignNurseToOrder") {
        if (getData?.ok) {
          console.log("Nurse assigned successfully:", getData.ok);
          setState((prev) => ({ ...prev, selectedNurse: getData.ok }));
          reloadOrders();
        }
        if (getData?.smsError) {
          console.error("SMS Error:", getData.smsError);
          toast.error(getData.smsError);
        }
        if (getData?.addBonus) {
          console.log("Bonus added:", getData.addBonus);
          toast.success(getData.addBonus);
        }
        if (getData?.smsWarning) {
          console.warn("SMS Warning:", getData.smsWarning);
          toast.warning(getData.smsWarning);
        }
      }
    });
  };

  const renderPages = () => {
    if (!state.response.nurses || !state.response.nurses[0]) return null;

    const pages = [];
    let page = state.startPage;

    pages.push(
      <button key="btnStart" onClick={() => changeStartPage("-")}>
        &laquo;
      </button>
    );

    for (let i = 1; i <= 10; i++) {
      const pageNumber = page + i;
      if (pageNumber <= parseInt(state.response.totalPages)) {
        const setPage = pageNumber - 1;
        pages.push(
          <button
            className={state.page + 1 === pageNumber ? "active" : ""}
            key={pageNumber}
            onClick={() => changePage(setPage)}
          >
            {pageNumber}
          </button>
        );
      }
    }

    pages.push(
      <button key="btnEnd" onClick={() => changeStartPage("+")}>
        &raquo;
      </button>
    );

    return pages;
  };

  // Add a function to clear filters if needed
  const clearFilters = () => {
    localStorage.removeItem('orderFilters');
    setState((prev) => ({
      ...prev,
      priority: "",
      district: "",
      freeSearch: "",
      page: 0,
      startPage: 0,
      orderField: "priorityId",
      orderAscDesc: "DESC"
    }));
  };

  console.log("Current state:", state);

  return (
    <div>
      <img
        src={loader}
        alt="loader"
        className={
          !state.loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {orderData?.hospitalId === "9" &&
        orderData?.hospitalDivisionId === "2" &&
        user?.userCredential === "1" && (
          <div className="bonusWorker">
            <Form.Check
              className={`holydayShitch ${state.workerBonus ? "active" : ""}`}
              checked={state.workerBonus}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  workerBonus: !prev.workerBonus,
                }))
              }
              type="switch"
              id="custom-switch"
              label="הוספת בונוס לעובד:"
            />
          </div>
        )}

      {orderData && (
        <ul className="showOutdated addPadd">
          {user.userCredential === "1" && (
            <li>
              <button className="reloadBtn" onClick={getNurses}>
                <i className="fas fa-sync-alt"></i>
              </button>
            </li>
          )}
          <li>
            <span>תאריך משמרת: </span>
            <strong className="numbers">{orderData.targetDayShow}</strong>
          </li>
          <li>
            <span>בית חולים: </span>
            <strong className="">{orderData.hospital}</strong>
          </li>
          <li>
            <span>סוג משמרת: </span>
            <strong>{orderData.hospitalShift}</strong>
          </li>
          {orderData.hospitalshiftId === "6" && (
            <li>
              <span>בין השעות: </span>
              <strong className="numbers">
                {orderData.emergencyFrom} ל- {orderData.emergencyTo}
              </strong>
            </li>
          )}
        </ul>
      )}

      {state.selectedNurse && state.selectedNurse.id ? (
        <div className="showselectedNurse animate__animated animate__fadeIn">
          <ul>
            <li>{state.selectedNurse.name}</li>
            <li>{state.selectedNurse.phone}</li>
            {user.hospital && user.hospital.length === 0 && (
              <>
                <li>{state.selectedNurse.email}</li>
                <li>{state.selectedNurse.district}</li>
                <li>
                  <div
                    className={`showPriority colorId${state.selectedNurse.priorityId}`}
                  >
                    {" "}
                    {state.selectedNurse.priority}{" "}
                  </div>
                </li>
              </>
            )}
            <WorkerConfirm
              orderData={orderData}
              showConfirm={true}
              output="li"
            />
            {(!orderData ||
              orderData?.is_retroOrder ||
              (!orderData.is_outdated &&
                orderData.orderStatus !== "2" &&
                orderData.orderStatus !== "3")) && (
              <li>
                {user.hospital && user.hospital.length === 0 && (
                  <>
                    <button
                      className="btnChange"
                      onClick={() => changeSelectedNurse()}
                    >
                      <img src={changeIcon} alt="החלף" />
                      <span>החלפה</span>
                    </button>
                    <button
                      className="btnChange"
                      style={{ margin: "0 5px 0 0" }}
                      onClick={confirmOrderLink}
                    >
                      <span>אישור הזמנה ידני</span>
                    </button>
                  </>
                )}
              </li>
            )}
          </ul>
        </div>
      ) : (
        <div>
          {state.editNurseData ? (
            <WorkerEdit
              nurseData={state.editNurseData}
              is_newNurse={false}
              goBack={goBack}
              currentSearchStatus={{
                orderField: state.orderField,
                orderAscDesc: state.orderAscDesc,
                priority: state.priority,
                district: state.district,
                freeSearch: state.freeSearch,
              }}
            />
          ) : (
            <div className="showListAndSearchWorkers">
              {orderData.nurseData && orderData.nurseData.id && (
                <div className="goBackBtnCont addPadd">
                  <button
                    onClick={() => changeSelectedNurse(orderData.nurseData)}
                    className="goBackBtn bounce2"
                  >
                    « חזור
                  </button>
                </div>
              )}
              {user.hospital && user.hospital.length === 0 ? (
                <>
                  <WorkerLinkSearch
                    currentSearchStatus={{
                      orderField: state.orderField,
                      orderAscDesc: state.orderAscDesc,
                      priority: state.priority,
                      district: state.district,
                      freeSearch: state.freeSearch,
                    }}
                    updateValues={updateSearchValues}
                  />
                  {state.response.nurses && state.response.nurses.length > 0 ? (
                    <div className="addPadd" style={{ minHeight: "310px" }}>
                      <WorkersList
                        editNurse={(nurseData) =>
                          setState((prev) => ({
                            ...prev,
                            editNurseData: nurseData,
                          }))
                        }
                        selectNurse={selectNurse}
                        is_select={is_4selectWorker}
                        nurses={state.response.nurses}
                        changeOrder={changeOrder}
                        currentOrder={{
                          name: state.orderField,
                          order: state.orderAscDesc,
                        }}
                      />
                    </div>
                  ) : (
                    <h2 className="errorH2 addPadd">
                      אין עובדים להציג על פי החיפוש.
                    </h2>
                  )}
                  {state.response.nurses && state.response.nurses[0] && (
                    <div className="pagesNumbers">{renderPages()}</div>
                  )}
                </>
              ) : (
                <div className="addPadd">
                  <br />
                  <h3 style={{ fontSize: "20px", color: "red" }}>
                    לא קיים עובד
                  </h3>
                  <br />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkerLinkMain;
