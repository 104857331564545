import React from 'react';
import WorkersTD from './WorkersTD';
import { Form, FloatingLabel } from "react-bootstrap";

const WorkersList = ({ nurses, is_select, selectNurse, editNurse, changeOrder, currentOrder }) => {
    const [state, setState] = React.useState({
        currentOrder: { name: currentOrder.name, order: currentOrder.order },
        priority: { name: 'priority', order: 'ASC' },
        district: { name: 'district', order: 'ASC' },
        name: { name: 'name', order: 'ASC' },
        phone: { name: 'phone', order: 'ASC' },
        email: { name: 'email', order: 'ASC' },
        smsMessage: ''
    });

    const sendNurseData = (nurseData) => {
        if (is_select) {
            selectNurse(nurseData.id, state.smsMessage);
        } else {
            editNurse(nurseData);
        }
    };

    const handleOrderChange = (field) => {
        const order = field.order === 'DESC' ? 'ASC' : 'DESC';
        const changeOrder = {
            name: field.name,
            order: order
        };

        setState(prev => ({
            ...prev,
            [field.name]: changeOrder,
            currentOrder: changeOrder
        }));

        changeOrder(changeOrder);
    };

    const menuTable = [
        { fieldName: 'priority', text: 'תעדוף' },
        { fieldName: 'district', text: 'מחוז' },
        { fieldName: 'name', text: 'שם מטפל' },
        { fieldName: 'phone', text: 'טלפון' },
        { fieldName: 'email', text: 'אימייל' }
    ];

    return (
        <div>
            <div className="defaultTable workersTable">
                
                {is_select && (
                    <div className='smsMessage'>
                        <FloatingLabel label='מלל חופשי לשליחת SMS לעובד/ת'>
                            <Form.Control
                                className="customFloatInput"
                                type='text'
                                name="smsMessage"
                                disabled={false}
                                placeholder='מלל חופשי לשליחת SMS לעובד/ת'
                                onChange={(event) => setState(prev => ({ ...prev, smsMessage: event.target.value }))}
                                value={state.smsMessage}
                            />
                        </FloatingLabel>
                    </div>
                )}

                <div className="tableTR titleRow">
                    <div className='tableTD'>#</div>
                    {menuTable.map(item => (
                        <div 
                            key={item.fieldName} 
                            className={state.currentOrder.name === item.fieldName ? 'tableTD active' : 'tableTD'}
                        >
                            <button onClick={() => handleOrderChange(state[item.fieldName])}>
                                {item.text} 
                                <i className={`fas fa-chevron-${state[item.fieldName].order === 'DESC' ? 'up' : 'down'}`}></i>
                            </button>
                        </div>
                    ))}
                </div>

                {nurses.map(item => (
                    <WorkersTD
                        key={item.id}
                        item={item}
                        is_select={is_select}
                        sendNurseData={sendNurseData}
                    />
                ))}
            </div>
        </div>
    );
};

export default WorkersList;
