import React, { Component } from 'react'
import OrdersTopCards from './OrdersTopCards';
import OrdersList from './OrdersList'

import addIco from './../../../img/nathan/svg/add.svg'
import filterIco from './../../../img/nathan/svg/filter.svg'

import getDataFromApi from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import OrdersSearch from "./OrdersSearch"
import OrderSms from "./OrderSms"

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom'; 

import SingleOrder from './SingleOrder';

export default class Orders extends Component {

    constructor(props) {
        super(props)
    
        this.state = {

            editOrderId : false,

            page : 0,
            startPage : 0,
            limit: this.props.limit ? this.props.limit : 5,

            response : false,
            loading : false,
            showSearch : false,

            orderField : 'targetDay',
            orderAscDesc : 'ASC',
            filterStatus : '',
            hospitalId : '',
            freeSearch : '',
        }
    }

    getOrders = () => {

        let sendObj = {

            orderField : this.state.orderField,
            orderAscDesc :  this.state.orderAscDesc,
            filterStatus : this.state.filterStatus,
            hospitalId : this.state.hospitalId,
            freeSearch : this.state.freeSearch,
            page : this.state.page,
            startPage : this.state.startPage,
            limit: this.state.limit

        };
        

        this.sendtoAdmin('getOrders','orders',sendObj,'response');
        
    }
    
    changeOrder = (order) => {

        //console.log(order);

        this.setState({
            orderField : order.name,
            orderAscDesc : order.order
        }, () => {

            this.getOrders();

        })
        
    }

    changeFilterStatus = (status) => {

        //console.log(order);

        this.setState({
            page : 0,
            filterStatus : status
        }, () => {

            this.getOrders();

        })
        
    }


    showHideSearch = () => {
        
       /*  this.setState({
            showSearch : !this.state.showSearch
        }); */

        if(this.state.showSearch) {
            this.setState({
                showSearch : false,
                filterStatus : '',
                hospitalId : '',
                freeSearch : '',
                page : 0
            }, () => this.getOrders() )
        } else {

            this.setState({
                showSearch : true
            } );
        }


    }
    
    
    updateSearchValues = (values) => {

        values.page = 0;

        this.setState( values, () => {
            this.getOrders();
        } );
        

    }


    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const filterState = {
            orderField: urlParams.get('orderField') || 'targetDay',
            orderAscDesc: urlParams.get('orderAscDesc') || 'ASC',
            filterStatus: urlParams.get('filterStatus') || '',
            hospitalId: urlParams.get('hospitalId') || '',
            freeSearch: urlParams.get('freeSearch') || '',
            page: parseInt(urlParams.get('page')) || 0,
            startPage: parseInt(urlParams.get('startPage')) || 0
        };
        
        this.setState(filterState, () => {
            this.getOrders();
        });
    }


    changePage = (setPage) => {

        this.setState({ page : setPage }, function() {
        
            this.getOrders();

        })
    }

    changeStartPage = (action) => {

        let changePage;

        if(action === '+') {

            changePage = this.state.startPage + 10  <=  this.state.response.totalPages -1 ? this.state.startPage + 10 : this.state.response.totalPages-1;

        } else if (action === '-') {
            changePage = this.state.startPage - 10  <  0 ? 0 : this.state.startPage - 10;
        }

        

        this.setState({ startPage : changePage }, function() {
        
            this.getOrders();

        })
    }

    editOrder = ( id ) => {
        
        this.setState({
            editOrderId : id
        });

    }
    
    goBack = (states) => {
        
        this.setState( states, ()=> {
                if(states.reloadOrders) {
                    this.getOrders();
                }
        } )
        
    }
    

    render() {

        //console.log(this.props);

        let currentSearchStatus = {
            orderField: this.state.orderField,
            orderAscDesc: this.state.orderAscDesc,
            filterStatus: this.state.filterStatus,
            hospitalId: this.state.hospitalId,
            freeSearch: this.state.freeSearch,
            page: this.state.page,
            startPage: this.state.startPage
        };

        const pages = [];
        let page = this.state.startPage;

        if(this.state.response.orders && this.state.response.orders[0]) {


            pages.push(<button key="btnStart" onClick= {()=>this.changeStartPage('-')} >&laquo;</button>);

            for (let i = 1; i <= 10; i++) {

                let pageNumber = page + i;

                if ( pageNumber <= parseInt(this.state.response.totalPages) ) {
                    let setPage = parseInt(pageNumber) - 1;
                        pages.push(<button className={this.state.page + 1 === pageNumber ? 'active' : ''} key={pageNumber} onClick= {()=>this.changePage(setPage)} >{pageNumber}</button>);
                }

            };
            
            pages.push(<button key="btnEnd" onClick= {()=>this.changeStartPage( '+' )} >&raquo;</button>);

        }

        return (
            <div className="ordersPage animate__animated animate__fadeIn">
                
                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                {this.state.editOrderId ? 

                    <SingleOrder currentSearchStatus = { currentSearchStatus } editOrderId = { this.state.editOrderId } goBack = { ( states ) => this.goBack( states ) } />

                    : <div className="smallWrapper">

                    <OrdersTopCards filteredStatus={ this.state.filterStatus }  changeFilterStatus={ (status) => this.changeFilterStatus(status) } />

                    <h1 className="titleStyle">הזמנות</h1>

                    <div className="buttonsOrder">
                        <button className='reloadBtn' onClick={ ()=>  this.getOrders() } >
                            <i className="fas fa-sync-alt"></i>
                        </button>
                        
                        <NavLink
                        className="defaultBtnPic violet"
                        role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                        to='/order' >

                            <img src={addIco} alt='הוספה'/>
                            <span>הוספת הזמנה</span>

                        </NavLink>

                       {/*  <button className="defaultBtnPic violet" onClick={ ()=> console.log('addNewOrder') } >
                            <img src={addIco} alt='הוספה'/>
                            <span>הוספת הזמנה</span>
                        </button> */}

                        <button className="defaultBtnPic grey" onClick={ ()=> this.showHideSearch() } >
                            <img src={filterIco} alt='חיפוש מתקדם'/>
                            {this.state.showSearch ? <span style={{ color : '#0058FF'}} >איפוס חיפוש</span> : <span>חיפוש מתקדם</span> }
                        </button>

                        < OrderSms />
                    </div>

                    { this.state.showSearch ? <OrdersSearch currentSearchStatus = {currentSearchStatus}  updateValues ={ ( values ) => this.updateSearchValues( values ) } /> : false }

                    {this.state.response.orders && this.state.response.orders.length > 0 ? 
                        <div style={{ minHeight : '450px' }} >
                            <OrdersList 
                                editOrder = { (orderId) => this.editOrder(orderId) }
                                orders = { this.state.response.orders }
                                changeOrder = { (order) => this.changeOrder(order) }
                                currentOrder = { { name : this.state.orderField , order : this.state.orderAscDesc } }
                        /></div>
                        : <h2 className='errorH2'>אין הזמנות להציג על פי החיפוש.</h2> }

                    {this.state.response.orders && this.state.response.orders[0] ? <div className="pagesNumbers">

                    {pages}


                    </div> : false }
                    
                </div> }
                
            </div>
            
        )
    }


    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {


        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false,
        
        }, function(){

            //console.log(getData);

            /* if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
            } */
            
            
        }))
    }

}
